import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './Burger.module.scss'
import { Button } from '@kogk/common'
import { Burgers, Times } from '@src/assets'

const Burger = props => {
  const { active, onClick, className } = props
  const containerClass = [
    className,
    styles.container,
    {
      [styles.active]: active
    }
  ]
  const ref = useRef()

  return (
    <Button
      className={containerClass}
      ref={ref}
      onClick={onClick}
      data-cy='burger-icon'
      aria-haspopup='true'
      aria-expanded={active}
      aria-label='mobile menu'
    >
      <div className={styles.lineContainer}>
        {active ? <Times /> : <Burgers />}
      </div>
    </Button>
  )
}

Burger.propTypes = {
  /** Toggles component active state */
  active: PropTypes.bool,
  /** onClick event handler */
  onClick: PropTypes.func
}
Burger.defaultProps = {
  active: false
}

export default Burger
